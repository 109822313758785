import React from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";

const RebateDetailsTable = ({ contract, handleDeleteRebateDetail }) =>
  contract?.rebate_details?.length ? (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="rebate details table">
        <TableHead>
          <TableRow>
            <TableCell>NDC</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Additional Data</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contract.rebate_details.map(({ id, ndc11, data, value, rebate_type }) => (
            <TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>{ndc11 || "--"}</TableCell>
              <TableCell>{rebate_type.name}</TableCell>
              <TableCell>{value}</TableCell>
              <TableCell>{data?.base_wac ? `Base Wac: ${data.base_wac}` : "--"}</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => handleDeleteRebateDetail(id, "rebate_details")}
                  variant="outlined"
                  color="error"
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <p>No rebate details have been added to this contract yet.</p>
  );

RebateDetailsTable.propTypes = {
  contract: PropTypes.shape({
    rebate_details: PropTypes.array,
  }).isRequired,
  handleDeleteRebateDetail: PropTypes.func.isRequired,
};

export default RebateDetailsTable;
