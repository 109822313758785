import React from "react";
import PropTypes from "prop-types";
import { ResponsivePie } from "@nivo/pie";
import { Skeleton } from "@mui/material";

const Donut = ({
  data,
  isLoading,
  activeItem,
  setActiveItem,
  renderCenterText,
  height,
  noDataMessage,
}) => {
  function handleHoverEvent(hoveredItem, event) {
    setActiveItem(hoveredItem);
    event.target.style.cursor = hoveredItem ? "pointer" : "default";
  }

  if (!isLoading && !data.length) return <p>{noDataMessage}</p>;
  if (isLoading) return <Skeleton animation="wave" variant="rounded" height={height} width="60%" />;

  return (
    <div className="donut" style={{ height: `${height}px` }}>
      <ResponsivePie
        data={data}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        innerRadius={0.7}
        cornerRadius={1}
        activeInnerRadiusOffset={6}
        activeOuterRadiusOffset={6}
        borderWidth={1}
        colors={(e) => e.data.color}
        borderColor={{ from: "color" }}
        activeId={activeItem}
        onMouseEnter={(datum, event) => handleHoverEvent(datum.data.id, event)}
        onMouseLeave={(_, event) => handleHoverEvent(null, event)}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        tooltip={() => <></>}
        layers={["arcs", "arcLabels", "arcLinkLabels", "legends", renderCenterText]}
      />
    </div>
  );
};

Donut.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  activeItem: PropTypes.string,
  setActiveItem: PropTypes.func,
  renderCenterText: PropTypes.func,
  height: PropTypes.number,
  noDataMessage: PropTypes.string,
};

Donut.defaultProps = {
  data: [],
  isLoading: false,
  activeItem: null,
  setActiveItem: () => {},
  renderCenterText: null,
  height: 188,
  noDataMessage: "No data available at this time.",
};

export default Donut;
