import React, { useState, useMemo } from "react";
import Donut from "../common/charts/Donut";
import { addCommasToNumber } from "../utils/numberUtils";

const MOCK_DATA = [
  { id: "Xarelto", value: 362_367, color: "#3246D3" },
  { id: "Stelara", value: 34_749, color: "#287DFC" },
  { id: "Tremfya", value: 6_549, color: "#92D460" },
  { id: "Other", value: 1_184, color: "#C382F2" },
];

const MatchesByProduct = () => {
  const [activeItem, setActiveItem] = useState(null);

  const totalMatches = useMemo(() => MOCK_DATA.reduce((acc, curr) => curr.value + acc, 0), []);

  return (
    <div className="card" data-testid="matches-by-product">
      <div className="card__title">Breakdown of Matches by Product</div>
      <div className="donut__container">
        <Donut data={MOCK_DATA} activeItem={activeItem} setActiveItem={setActiveItem} />
        <div className="donut__table">
          <div className="donut__table__row donut__table__row--header">
            <div className="donut__table__row__item--min-width-35">Product</div>
            <div className="donut__table__row__item--align-right">Matches</div>
            <div className="donut__table__row__item--align-right donut__table__row__item--min-width-40">
              % of Total
            </div>
          </div>
          {MOCK_DATA.map((obj) => {
            const formattedValue = addCommasToNumber(obj.value);
            const percentOfTotal = `${((obj.value / totalMatches) * 100).toFixed(1)}%`;

            return (
              <div
                key={obj.id}
                className={`donut__table__row donut__table__row--body ${activeItem === obj.id ? "donut__table__row--active" : ""}`}
                onMouseEnter={() => setActiveItem(obj.id)}
                onMouseLeave={() => setActiveItem(null)}
              >
                <div className="donut__table__body__item--id donut__table__row__item--min-width-35">
                  <div style={{ backgroundColor: obj.color }} />
                  <span>{obj.id}</span>
                </div>
                <div className="donut__table__row__item--align-right">{formattedValue}</div>
                <div className="donut__table__row__item--align-right donut__table__row__item--min-width-40">
                  {percentOfTotal}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MatchesByProduct;
