import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { UnfoldMore } from "@mui/icons-material";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer, notUndefined } from "@tanstack/react-virtual";
import useInvoiceMappingTableScroll from "./useInvoiceMappingTableScroll";
import useInitialColumnWidths from "./useInitialColumnWidths";

const InvoiceMappingTable = ({
  data,
  headers,
  selectedColumnIndex,
  selectedColumns,
  tableScrollLeftPositionRef,
}) => {
  const [opacity, setOpacity] = useState(0);

  const parentRef = useRef(null);
  const thRefs = useRef([]);

  useEffect(() => {
    // This is a workaround to prevent the table scrolling from flickering when it first renders
    setTimeout(() => {
      setOpacity(1);
    }, 0);
  }, []);

  const { handleScroll } = useInvoiceMappingTableScroll(
    parentRef,
    thRefs,
    tableScrollLeftPositionRef,
    selectedColumnIndex,
  );

  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    return headers.map((key, index) => {
      return columnHelper.accessor(key, {
        header: key.toString(),
        cell: (info) => info.row.original[index],
        id: `column-${index}`,
      });
    });
  }, [data, headers]);

  const { getCellWidth } = useInitialColumnWidths(columns, data);

  const table = useReactTable({
    data,
    columns,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 48,
    overscan: 20,
  });

  const items = virtualizer.getVirtualItems();
  const [before, after] =
    items.length > 0
      ? [
          notUndefined(items[0]).start - virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() - notUndefined(items[items.length - 1]).end,
        ]
      : [0, 0];
  const colSpan = columns.length;

  const renderVirtualRows = () => {
    return items.map((virtualRow) => {
      const row = rows[virtualRow.index];
      return (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell, index) => {
            return (
              <td key={cell.id} className={selectedIndexes.includes(index) ? "highlight" : ""}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const selectedIndexes = Object.keys(selectedColumns).map((index) => parseInt(index));

  return (
    <div
      ref={parentRef}
      className="table--scroll"
      style={{ borderRadius: "8px 8px 0 0", opacity }}
      onScroll={handleScroll}
    >
      <table className="table table--virtualized table--secondary table--layout-fixed">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                const cellWidth = getCellWidth(index);
                const headerText = header.column.columnDef.header;

                return (
                  <th
                    key={header.id}
                    ref={(el) => (thRefs.current[index] = el)}
                    colSpan={header.colSpan}
                    className={selectedIndexes.includes(index) ? "highlight" : ""}
                    style={{ width: cellWidth }}
                  >
                    {selectedColumns?.[index] && (
                      <span className="table__column-badge" title={selectedColumns[index]}>
                        {selectedColumns[index]}
                      </span>
                    )}
                    <div style={{ width: cellWidth }} title={headerText}>
                      {flexRender(headerText, header.getContext())}
                      {header.column.getCanSort() && (
                        <UnfoldMore
                          className="sort-icon"
                          data-testid={`${header.column.id}-sort-icon`}
                          onClick={header.column.getToggleSortingHandler()}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {before > 0 && (
            <tr>
              <td colSpan={colSpan} style={{ height: before }} />
            </tr>
          )}
          {renderVirtualRows()}
          {after > 0 && (
            <tr>
              <td colSpan={colSpan} style={{ height: after }} />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

InvoiceMappingTable.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedColumns: PropTypes.object,
  selectedColumnIndex: PropTypes.number,
  tableScrollLeftPositionRef: PropTypes.object.isRequired,
};

InvoiceMappingTable.defaultProps = {
  selectedColumns: {},
  selectedColumnIndex: undefined,
};

export default InvoiceMappingTable;
