import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, ListItemText } from "@mui/material";
import StatusChip from "./StatusChip";

const InvoiceDetailsList = ({ invoice }) => {
  const invoiceDetails = [
    { primary: "Number", secondary: invoice.number },
    { primary: "Date", secondary: invoice.date },
    { primary: "Due Date", secondary: invoice.due_date },
    { primary: "Total Amount", secondary: invoice.total_amount },
    { primary: "Calculated Rebate Amount", secondary: invoice.calculated_rebate_amount },
    { primary: "Validated Rebate Amount", secondary: invoice.validated_rebate_amount },
    { primary: "Calculation Variance", secondary: invoice.calculation_variance },
    { primary: "Scrubbed Amount", secondary: invoice.scrubbed_amount },
    { primary: "Customer Name", secondary: invoice.customer_name },
    {
      primary: "Filenames",
      secondary: invoice.files?.map((file) => file.filename).join(", ") || "No files",
    },
    {
      primary: "File URLs",
      secondary: invoice.files?.map((file) => file.file_url).join(", ") || "No files",
    },
    { primary: "Status", secondary: <StatusChip status={invoice.status} /> },
  ];

  return (
    <List>
      {invoiceDetails.map(({ primary, secondary }) => (
        <ListItem key={primary} disableGutters>
          <ListItemText primary={primary} secondary={secondary} />
        </ListItem>
      ))}
    </List>
  );
};

InvoiceDetailsList.propTypes = {
  invoice: PropTypes.shape({
    number: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    due_date: PropTypes.string.isRequired,
    total_amount: PropTypes.string.isRequired,
    calculated_rebate_amount: PropTypes.string.isRequired,
    validated_rebate_amount: PropTypes.string.isRequired,
    calculation_variance: PropTypes.string.isRequired,
    scrubbed_amount: PropTypes.string.isRequired,
    customer_name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        filename: PropTypes.string.isRequired,
        file_url: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default InvoiceDetailsList;
