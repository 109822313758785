import React from "react";
import PropTypes from "prop-types";
import LoadingSkeletonWrapper from "./LoadingSkeletonWrapper";

const MetricCard = ({ title, value, time, visualElement, isLoading, noDataMessage }) => {
  const hasBadValue = !isLoading && (value === null || value === undefined || value === "");

  function renderMetrics() {
    if (hasBadValue) return <p>{noDataMessage}</p>;

    return (
      <>
        <div className="metric-card__body">
          <LoadingSkeletonWrapper isLoading={isLoading} width={150}>
            <span>{value}</span>
          </LoadingSkeletonWrapper>
        </div>
        <div className="metric-card__footer">
          <LoadingSkeletonWrapper isLoading={isLoading} width={80}>
            <span>{time}</span>
          </LoadingSkeletonWrapper>
        </div>
      </>
    );
  }

  return (
    <div className="metric-card" data-testid="metric-card">
      <div className="metric-card__details">
        <div className="metric-card__header">
          <span>{title}</span>
        </div>
        {renderMetrics()}
      </div>
      {visualElement && <div className="metric-card__visual-element">{visualElement}</div>}
    </div>
  );
};

MetricCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  time: PropTypes.string,
  visualElement: PropTypes.node,
  isLoading: PropTypes.bool,
  noDataMessage: PropTypes.string,
};

MetricCard.defaultProps = {
  value: "N/A",
  time: "All Time",
  visualElement: null,
  isLoading: false,
  noDataMessage: "No data available at this time.",
};

export default MetricCard;
