import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Link,
  Button,
  Breadcrumbs,
  Typography,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import ContractDetailsList from "./ContractDetailsList";
import ScrubRulesTable from "./ScrubRulesTable";
import RebateTypesTable from "./RebateTypesTable";
import RebateDetailsTable from "./RebateDetailsTable";
import { handleDeleteResource } from "../../services/resourceService";

const AddRebateDetailButton = ({ rebateTypes, contract }) => {
  const button = (
    <Button
      disabled={!rebateTypes.length}
      variant="contained"
      href={`/contracts/${contract.id}/rebate_details/new`}
    >
      Add Rebate Detail
    </Button>
  );

  return !rebateTypes.length ? (
    <Tooltip title="Create a rebate type before adding a rebate detail">
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};

AddRebateDetailButton.propTypes = {
  rebateTypes: PropTypes.array.isRequired,
  contract: PropTypes.object.isRequired,
};

const ContractDetails = ({ contract: initialContract, customers }) => {
  const [contract, setContract] = useState(initialContract);
  const [errorState, setErrorState] = useState({
    hasError: false,
    message: "",
  });

  const customer = customers.find(({ id }) => id === contract.customer_id);

  const rebateTypes = contract.customer?.rebate_types || [];

  const handleError = (error) => {
    setErrorState({
      hasError: true,
      message: error,
    });
  };

  const handleDeleteRule = (id, type) => {
    handleDeleteResource(id, type)
      .then(() => {
        const updatedContract = { ...contract };
        const updatedRules = updatedContract.rules.filter((rule) => rule.id !== id);
        updatedContract.rules = updatedRules;
        setContract(updatedContract);
      })
      .catch(() => {
        handleError("There was a problem deleting your rule. Please try again later.");
      });
  };

  const handleDeleteRebateType = (id, type) => {
    handleDeleteResource(id, type)
      .then(() => {
        const updatedContract = { ...contract };
        const updatedRebateTypes = updatedContract.customer.rebate_types.filter(
          (rebateType) => rebateType.id !== id,
        );
        updatedContract.customer.rebate_types = updatedRebateTypes;
        setContract(updatedContract);
      })
      .catch(() => {
        handleError("There was a problem deleting your rebate type. Please try again later.");
      });
  };

  const handleDeleteRebateDetail = (id, type) => {
    handleDeleteResource(id, type)
      .then(() => {
        const updatedContract = { ...contract };
        const updatedRebateDetails = updatedContract.rebate_details.filter(
          (rebateDetail) => rebateDetail.id !== id,
        );
        updatedContract.rebate_details = updatedRebateDetails;
        setContract(updatedContract);
      })
      .catch(() => {
        handleError("There was a problem deleting your rebate detail. Please try again later.");
      });
  };

  const handleAlertClose = () => {
    setErrorState({
      hasError: false,
      message: "",
    });
  };

  return (
    <div className="contract-details">
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <Link underline="hover" color="inherit" href="/contracts">
                Contracts
              </Link>
              <Typography color="text.primary">Contract Details</Typography>
            </Breadcrumbs>
            <h1 className="customer-selection__title">Contract Details</h1>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <Grid container>
            <Grid item>
              <ContractDetailsList contract={contract} customer={customer} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Link href={`/contracts/${contract.id}/edit`}>Edit Contract</Link>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <h2>Scrub Rules</h2>
                </Grid>
                <Grid item>
                  <Button variant="contained" href={`/contracts/${contract.id}/rules/new`}>
                    Add Rule
                  </Button>
                </Grid>
              </Grid>
              <ScrubRulesTable contract={contract} handleDeleteRule={handleDeleteRule} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <h2>Rebate Types</h2>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    href={`/customers/${contract.customer_id}/rebate_types/new`}
                  >
                    Add Rebate Type
                  </Button>
                </Grid>
              </Grid>
              <RebateTypesTable
                rebateTypes={rebateTypes}
                contract={contract}
                handleDeleteRebateType={handleDeleteRebateType}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <h2>Rebate Details</h2>
                </Grid>
                <Grid item>
                  <AddRebateDetailButton rebateTypes={rebateTypes} contract={contract} />
                </Grid>
              </Grid>
              <RebateDetailsTable
                contract={contract}
                handleDeleteRebateDetail={handleDeleteRebateDetail}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={errorState.hasError} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

ContractDetails.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    effective_date: PropTypes.string,
    expiration_date: PropTypes.string,
    termination_date: PropTypes.string,
    customer_id: PropTypes.string,
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        data: PropTypes.shape({
          uniqueness_columns: PropTypes.arrayOf(PropTypes.string),
          element: PropTypes.string,
        }),
      }),
    ),
    customer: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      rebate_types: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          base_type: PropTypes.string,
        }),
      ),
    }),
  }),
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      rebate_types: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          base_type: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

export default ContractDetails;
