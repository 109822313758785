import React from "react";
import PropTypes from "prop-types";

const Breadcrumbs = ({ breadcrumbs, handleBreadcrumbClick }) => {
  return (
    <div className="breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => {
        const isActiveStep = Object.keys(breadcrumbs).length - 1 === index;

        return (
          <button
            className={`breadcrumbs__item ${isActiveStep ? "breadcrumbs__item--active" : ""}`}
            key={index}
            onClick={() => handleBreadcrumbClick(breadcrumb)}
          >
            <span>{breadcrumb}</span>
            {isActiveStep ? null : (
              <span className="material-symbols-rounded" data-testid="breadcrumb-separator">
                keyboard_arrow_right
              </span>
            )}
          </button>
        );
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleBreadcrumbClick: PropTypes.func.isRequired,
};

export default Breadcrumbs;
