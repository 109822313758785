import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Snackbar, Alert } from "@mui/material";

function Notice({ details }) {
  const [state, setState] = useState(details);

  useEffect(() => {
    setState(details);
  }, [details]);

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={state.open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert elevation={5} variant="filled" onClose={handleClose} severity={state.kind}>
        {state.message}
      </Alert>
    </Snackbar>
  );
}

Notice.propTypes = {
  details: PropTypes.shape({
    kind: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
};

export default Notice;
