import React from "react";
import PropTypes from "prop-types";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { formatCurrency } from "../../utils/numberUtils";

const InvoiceDetailsList = ({ invoiceLines }) =>
  invoiceLines.length ? (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="invoice lines">
        <TableHead>
          <TableRow>
            <TableCell>Invoice Line ID</TableCell>
            <TableCell>NDC</TableCell>
            <TableCell>Claim #</TableCell>
            <TableCell align="right">WAC</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceLines.map(({ id, ndc11, provider_claim_number, wac }) => (
            <TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>{id.substring(0, 5)}</TableCell>
              <TableCell>{ndc11}</TableCell>
              <TableCell>{provider_claim_number}</TableCell>
              <TableCell align="right">{formatCurrency(wac)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <p>No invoice lines.</p>
  );

InvoiceDetailsList.propTypes = {
  invoiceLines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      ndc11: PropTypes.string.isRequired,
      provider_claim_number: PropTypes.string.isRequired,
      wac: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default InvoiceDetailsList;
