import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatDateToISO } from "../utils/dateTimeUtils";

const NewContract = ({ customers }) => {
  const [name, setName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(undefined);
  const [expirationDate, setExpirationDate] = useState(undefined);

  return (
    <div className="new-contract">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/contracts">
                Contracts
              </Link>
              <Typography color="text.primary">New Contract</Typography>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item xs={5}>
          <h1>New Contract</h1>
          <form method="POST" action="/contracts">
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
            />
            <input type="hidden" name="contract[effective_date]" value={effectiveDate} />
            <input type="hidden" name="contract[expiration_date]" value={expirationDate} />
            <FormControl fullWidth margin="normal">
              <TextField
                name="contract[name]"
                label="Name"
                variant="outlined"
                value={name}
                required
                onChange={(event) => setName(event.target.value)}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="customer">Customer</InputLabel>
              <Select
                labelId="customer"
                name="contract[customer_id]"
                id="customer"
                value={customerId}
                label="Customer"
                required
                onChange={(event) => setCustomerId(event.target.value)}
              >
                {customers.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="contract[effective_date]"
                  label="Effective Date"
                  value={effectiveDate}
                  required
                  onChange={(newEffectiveDate) =>
                    setEffectiveDate(formatDateToISO(newEffectiveDate))
                  }
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="contract[expiration_date]"
                  label="Expiration Date"
                  value={expirationDate}
                  required
                  onChange={(newExpirationDate) =>
                    setExpirationDate(formatDateToISO(newExpirationDate))
                  }
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl margin="normal">
              <Button variant="contained" type="submit">
                Save
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

NewContract.propTypes = {
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default NewContract;
