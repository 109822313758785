import { useEffect } from "react";

const useInvoiceMappingTableScroll = (
  parentRef,
  thRefs,
  scrollLeftPosition,
  selectedColumnIndex,
) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (parentRef.current) {
        parentRef.current.scrollLeft = scrollLeftPosition.current;
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (
      selectedColumnIndex !== undefined &&
      parentRef.current &&
      thRefs.current[selectedColumnIndex]
    ) {
      const thElement = thRefs.current[selectedColumnIndex];
      const scrollableContainer = parentRef.current;
      const thRect = thElement.getBoundingClientRect();
      const containerRect = scrollableContainer.getBoundingClientRect();
      const offset = thRect.left - containerRect.left;
      scrollableContainer.scrollTo({
        left: scrollableContainer.scrollLeft + offset,
        behavior: "smooth",
      });
    }
  }, [selectedColumnIndex]);

  const handleScroll = () => {
    scrollLeftPosition.current = parentRef.current.scrollLeft;
  };

  return { handleScroll };
};

export default useInvoiceMappingTableScroll;
