import React from "react";
import PropTypes from "prop-types";
import { Grid, Link, Breadcrumbs, Typography } from "@mui/material";
import InvoiceDetailsList from "../../common/InvoiceDetailsList";

const InvoiceDetails = ({ invoice }) => {
  return (
    <div className="invoice-details">
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/invoices">
                Invoices
              </Link>
              <Typography color="text.primary">Invoice Details</Typography>
            </Breadcrumbs>
            <h1>Invoice Details</h1>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <Grid container>
            <Grid item>
              <InvoiceDetailsList invoice={invoice} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

InvoiceDetails.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    due_date: PropTypes.string.isRequired,
    total_amount: PropTypes.string.isRequired,
    calculated_rebate_amount: PropTypes.string.isRequired,
    validated_rebate_amount: PropTypes.string.isRequired,
    calculation_variance: PropTypes.string.isRequired,
    scrubbed_amount: PropTypes.string.isRequired,
    customer_name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    files: PropTypes.array.isRequired,
  }).isRequired,
};

export default InvoiceDetails;
