import React from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { VERTICAL_DUPLICATE, REQUIRED_ELEMENT, PROBABILISTIC_340B } from "../contractRuleTypes";

const formatRuleDataByType = (data, type) => {
  switch (type) {
    case VERTICAL_DUPLICATE:
      return `Uniqueness columns: ${data.uniqueness_columns.join(", ")}`;
    case REQUIRED_ELEMENT:
      return data.element;
    case PROBABILISTIC_340B:
      return data.threshold;
    default:
      return "--";
  }
};

const ScrubRulesTable = ({ contract, handleDeleteRule }) =>
  contract?.rules?.length ? (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="scrub rules table">
        <TableHead>
          <TableRow>
            <TableCell>Rule Name</TableCell>
            <TableCell>Data</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contract.rules.map(({ id, data, type }) => (
            <TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>{type}</TableCell>
              <TableCell>{formatRuleDataByType(data, type)}</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => handleDeleteRule(id, "rules")}
                  variant="outlined"
                  color="error"
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <p>No rules have been added to this contract yet.</p>
  );

ScrubRulesTable.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    effective_date: PropTypes.string,
    expiration_date: PropTypes.string,
    terminate_date: PropTypes.string,
    customer_id: PropTypes.string,
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        data: PropTypes.shape({
          uniqueness_columns: PropTypes.arrayOf(PropTypes.string),
          element: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  handleDeleteRule: PropTypes.func.isRequired,
};

export default ScrubRulesTable;
