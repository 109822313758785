import React, { useState, useEffect, useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDateLongTime } from "../utils/dateTimeUtils";
import { addCommasToNumber, formatToPercent } from "../utils/numberUtils";
import Notice from "../common/Notice";
import Table from "../common/Table";
import useFetch from "../hooks/useFetch";

const RunLog = () => {
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });
  const { isLoading, data: runLog, error } = useFetch("api/binary_runs/");

  useEffect(() => {
    if (error) {
      setNotice({
        kind: "error",
        open: true,
        message: "Error retrieving run log data",
      });
    }
  }, [isLoading]);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return [
      columnHelper.accessor("date", {
        header: "Run",
        cell: (info) => formatDateLongTime(info.getValue()),
        cellProps: { className: "fw-500" },
        size: 96,
      }),
      columnHelper.accessor("rebates", {
        header: "Rebates",
        headerProps: { className: "align-right" },
        cellProps: { className: "align-right" },
        cell: (info) => addCommasToNumber(info.getValue()),
        sortDescFirst: true,
        size: 96,
      }),
      columnHelper.accessor("scrubbed", {
        header: "Scrubbed",
        headerProps: { className: "align-right" },
        cellProps: { className: "align-right" },
        cell: (info) => addCommasToNumber(info.getValue()),
        sortDescFirst: true,
        size: 96,
      }),
      columnHelper.accessor("not_scrubbed", {
        header: "Not Scrubbed",
        headerProps: { className: "align-right" },
        cellProps: { className: "align-right" },
        cell: (info) => addCommasToNumber(info.getValue()),
        sortDescFirst: true,
        size: 96,
      }),
      columnHelper.accessor("invalid", {
        header: "Invalid",
        headerProps: { className: "align-right" },
        cellProps: { className: "align-right" },
        cell: (info) => addCommasToNumber(info.getValue()),
        sortDescFirst: true,
        size: 96,
      }),
      columnHelper.accessor("scrub_rate", {
        header: "Scrub Rate",
        headerProps: { className: "align-right" },
        cellProps: { className: "align-right" },
        cell: (info) => formatToPercent(info.getValue()),
        sortDescFirst: true,
        size: 96,
      }),
    ];
  }, [runLog]);

  function handleRedirect({ original }) {
    window.location.href = `/scrub_report?run=${original.scrub_id}`;
  }

  return (
    <>
      <div className="main-content__header">
        <h1 className="main-content__heading">Run Log</h1>
      </div>
      <div className="main-content__body">
        <div className="table--scroll">
          <Table
            data={runLog}
            columns={columns}
            tableOptions={{
              initialState: {
                sorting: [
                  {
                    id: "date",
                    desc: true,
                  },
                ],
              },
            }}
            onRowClick={handleRedirect}
            isLoading={isLoading}
            enableHover={true}
            setNotice={setNotice}
            layoutFixed={true}
          />
        </div>
      </div>
      <Notice details={notice} />
    </>
  );
};

export default RunLog;
