import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const formatRuleDataToString = (data) => {
  if (!data) return "";

  return Object.entries(data)
    .map(([key, value]) => `${key}: ${value}`)
    .join(", ");
};

const ScrubRulesTable = ({ scrubResults }) => (
  <TableContainer>
    <Table sx={{ minWidth: 650 }} aria-label="scrub rules">
      <TableHead>
        <TableRow>
          <TableCell>Invoice Line ID</TableCell>
          <TableCell>NDC</TableCell>
          <TableCell>Claim #</TableCell>
          <TableCell>Rule Name</TableCell>
          <TableCell>Rule Data</TableCell>
          <TableCell>Rule Result</TableCell>
          <TableCell>Metadata</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {scrubResults.map(({ id, invoice_line_id, invoice_line, rule, status, data }) => (
          <TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>{invoice_line_id.substring(0, 5)}</TableCell>
            <TableCell>{invoice_line.ndc11}</TableCell>
            <TableCell>{invoice_line.payor_claim_number}</TableCell>
            <TableCell>{rule.type}</TableCell>
            <TableCell>{formatRuleDataToString(rule.data)}</TableCell>
            <TableCell>{status}</TableCell>
            <TableCell>{formatRuleDataToString(data)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

ScrubRulesTable.propTypes = {
  scrubResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      invoice_line_id: PropTypes.string.isRequired,
      invoice_line: PropTypes.shape({
        ndc11: PropTypes.string.isRequired,
        payor_claim_number: PropTypes.string.isRequired,
      }).isRequired,
      rule: PropTypes.shape({
        type: PropTypes.string.isRequired,
        data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      }).isRequired,
      status: PropTypes.string.isRequired,
      data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    }),
  ).isRequired,
};

export default ScrubRulesTable;
