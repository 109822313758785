import React from "react";
import PropTypes from "prop-types";

const Badge = ({ children }) => {
  return (
    <div className="badge">
      <div className="badge__indicator" />
      {children}
    </div>
  );
};

Badge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

export default Badge;
