const stepDetails = {
  NO_ATTACHMENT: {
    breadcrumb: "Attach a File",
    step: "NO_ATTACHMENT",
    subheading: null,
  },
  SELECT_WORKSHEET: {
    breadcrumb: "Select Header Row",
    step: "SELECT_WORKSHEET",
    subheading: "Select Your Data",
  },
  MAP_COLUMN_DATA: {
    breadcrumb: "Map Data and Review",
    step: "MAP_COLUMN_DATA",
    subheading: "Column Mapping",
  },
};

export default stepDetails;
