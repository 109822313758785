import React from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";

const RebateTypesTable = ({ rebateTypes, handleDeleteRebateType }) =>
  rebateTypes.length ? (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="rebate types table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rebateTypes.map(({ id, name, base_type }) => (
            <TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>{name}</TableCell>
              <TableCell>{base_type}</TableCell>
              <TableCell align="right">
                <Button href={`/rebate_types/${id}/edit`}>Edit</Button>
                <Button
                  onClick={() => handleDeleteRebateType(id, "rebate_types")}
                  variant="outlined"
                  color="error"
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <p>No rebate types have been added to this contract yet.</p>
  );

RebateTypesTable.propTypes = {
  rebateTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      base_type: PropTypes.string,
    }),
  ).isRequired,
  handleDeleteRebateType: PropTypes.func.isRequired,
};

export default RebateTypesTable;
