import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import { Cloud, Done, Error, HourglassEmpty, PendingActions, Schedule } from "@mui/icons-material";

const capitalize = (string) => {
  if (typeof string !== "string") return "";

  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "default";
    case "scheduled":
      return "info";
    case "processing":
      return "warning";
    case "uploaded":
      return "info";
    case "processed":
      return "success";
    case "failed":
      return "error";
    default:
      return "default";
  }
};

const getStatusIcon = (status) => {
  switch (status) {
    case "pending":
      return <PendingActions />;
    case "scheduled":
      return <Schedule />;
    case "processing":
      return <HourglassEmpty />;
    case "uploaded":
      return <Cloud />;
    case "processed":
      return <Done />;
    case "failed":
      return <Error />;
    default:
      return null;
  }
};

const StatusChip = ({ status }) => (
  <Chip
    icon={getStatusIcon(status)}
    size="small"
    label={capitalize(status)}
    color={getStatusColor(status)}
  />
);

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusChip;
