import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Snackbar,
  Alert,
  FormHelperText,
} from "@mui/material";
import ScrubResultsTable from "./ScrubResultsTable";
import InvoiceDetailsList from "../../common/InvoiceDetailsList";
import StatusChip from "../../common/StatusChip";

const ScrubDetails = ({ scrub }) => {
  const [errorState, setErrorState] = useState({
    hasError: false,
    message: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const handlePerformScrub = () => {
    setIsProcessing(true);

    const formData = new FormData();
    formData.append(
      "authenticity_token",
      document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    );

    axios
      .post(`/scrubs/${scrub.id}/process`, formData)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setErrorState({
          hasError: true,
          message: "There was a problem performing the scrub. Please try again later.",
        });
      });
  };

  const handleAlertClose = () => {
    setErrorState({
      hasError: false,
      message: "",
    });
  };

  return (
    <div className="scrub-details">
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/invoices">
                Invoices
              </Link>
              <Link underline="hover" color="inherit" href={`/invoices/${scrub.invoice.id}`}>
                Invoice Details
              </Link>
              <Typography color="text.primary">Scrub Details</Typography>
            </Breadcrumbs>
            <h1 className="customer-selection__title">Scrub Details</h1>
            <List>
              <ListItem disableGutters>
                <ListItemText primary="Status" secondary={<StatusChip status={scrub.status} />} />
              </ListItem>
            </List>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <Grid container>
            <Grid item>
              <h2>Invoice Info</h2>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <InvoiceDetailsList invoice={scrub.invoice} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <h2>Contracts</h2>
              <List>
                {scrub.invoice.contracts.map(({ id, name }) => (
                  <ListItem key={id} disableGutters>
                    <Link href={`/contracts/${id}`}>{name}</Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <h2>Scrub Results</h2>
              <FormHelperText>Displaying the first 25 scrub results.</FormHelperText>
            </Grid>
            <Grid item xs={12}>
              {scrub.status === "pending" ? (
                <Button variant="contained" onClick={handlePerformScrub} disabled={isProcessing}>
                  Perform Scrub
                </Button>
              ) : (
                <ScrubResultsTable scrubResults={scrub.scrub_results_limited} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={errorState.hasError} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

ScrubDetails.propTypes = {
  scrub: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    invoice: PropTypes.shape({
      id: PropTypes.string,
      number: PropTypes.string,
      date: PropTypes.string,
      amount: PropTypes.string,
      contracts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
    scrub_results_limited: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
        contract_name: PropTypes.string,
        contract_id: PropTypes.string,
        invoice_number: PropTypes.string,
        invoice_date: PropTypes.string,
        invoice_amount: PropTypes.string,
        scrubbed_amount: PropTypes.string,
        scrubbed_date: PropTypes.string,
        scrubbed_number: PropTypes.string,
        scrubbed_status: PropTypes.string,
        scrubbed_type: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default ScrubDetails;
