import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, ListItemText } from "@mui/material";
import { formatDate } from "../../utils/dateTimeUtils";

const ContractDetailsList = ({ contract, customer }) => {
  const contractDetails = [
    { primary: "Name", secondary: contract.name },
    { primary: "Customer", secondary: customer.name },
    { primary: "Effective Date", secondary: formatDate(contract.effective_date) },
    { primary: "Expiration Date", secondary: formatDate(contract.expiration_date) },
  ];

  return (
    <List>
      {contractDetails.map(({ primary, secondary }) => (
        <ListItem key={primary} disableGutters>
          <ListItemText primary={primary} secondary={secondary} />
        </ListItem>
      ))}
    </List>
  );
};

ContractDetailsList.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    effective_date: PropTypes.string,
    expiration_date: PropTypes.string,
    termination_date: PropTypes.string,
    customer_id: PropTypes.string,
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        data: PropTypes.shape({
          uniqueness_columns: PropTypes.arrayOf(PropTypes.string),
          element: PropTypes.string,
        }),
      }),
    ),
    customer: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      rebate_types: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          base_type: PropTypes.string,
        }),
      ),
    }),
  }),
  customer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    rebate_types: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        base_type: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default ContractDetailsList;
