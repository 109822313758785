import "@hotwired/turbo";

var context = import.meta.glob("../../components/**/*.{js,jsx}", { eager: true });

let componentsContext = {};
for (const component in context) {
  componentsContext[component.replace(".jsx", "").replace(/.+components\//g, "")] =
    context[component].default;
}

import ReactRailsUJS from "react_ujs";

ReactRailsUJS.getConstructor = (name) => {
  return componentsContext[name];
};

// Prevent double mount on page load
ReactRailsUJS._firstTurboLoadSkipped = false;
ReactRailsUJS.handleEvent("turbo:load", () => {
  if (ReactRailsUJS._firstTurboLoadSkipped) ReactRailsUJS.handleMount();
  ReactRailsUJS._firstTurboLoadSkipped = true;
});

// Unmount components and call cleanup functions after Turbo navigations
ReactRailsUJS.handleEvent("turbo:before-render", (e) => {
  ReactRailsUJS.unmountComponents(e.target);
});
