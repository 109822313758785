import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { primaryText } = colors;

const fontFamily = "Roboto, sans-serif";

const barTextStyle = {
  fill: primaryText,
  fontSize: 10,
  fontFamily,
};

const barTheme = {
  axis: {
    ticks: {
      text: barTextStyle,
    },
  },
};

const noDataChartTheme = {
  axis: {
    ticks: {
      text: { ...barTextStyle, opacity: 0.5 },
    },
  },
};

export { barTheme, barTextStyle, noDataChartTheme };
