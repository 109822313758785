const createQueryString = (params) => {
  const queryString = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => queryString.append(`${key}[]`, val));
    } else if (value !== undefined && value !== null && value !== "") {
      queryString.append(key, value);
    }
  });

  return queryString.toString() ? `?${queryString.toString()}` : "";
};

const encodeSquareBracketsInUrl = (url) => {
  // Replace '[]' with '%5B%5D' in the URL string
  return url.replace(/\[\]/g, "%5B%5D");
};

export { createQueryString, encodeSquareBracketsInUrl };
