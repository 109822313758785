import { useState, useEffect } from "react";
import textWidth from "../../utils/textWidth";

const useInitialColumnWidths = (columns, data, header = true) => {
  const [columnWidths, setColumnWidths] = useState({});

  const fontSize = 12;
  const fontFamily = "Roboto, sans-serif";
  const MAX_WIDTH = 500;

  useEffect(() => {
    const calculateWidths = () => {
      const newWidths = {};
      columns.forEach((column, index) => {
        const headerWidth = header ? textWidth(column.header, fontSize, fontFamily) : 0;
        const maxContentWidth = data.slice(0, 100).reduce((maxWidth, row) => {
          const cellContent = row[index];
          const contentWidth = textWidth(cellContent, fontSize, fontFamily);
          return Math.max(maxWidth, contentWidth);
        }, 0);
        const maxWidthBasedOnContent = Math.max(headerWidth, maxContentWidth);
        newWidths[column.id] = Math.min(maxWidthBasedOnContent, MAX_WIDTH) + 18;
      });
      setColumnWidths(newWidths);
    };

    calculateWidths();
  }, [columns, data]);

  return {
    getCellWidth: (index) => columnWidths[`column-${index}`],
  };
};

export default useInitialColumnWidths;
