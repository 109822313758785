import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Add } from "@mui/icons-material";
import debounce from "debounce-promise";
import InvoicesTable from "./InvoicesTable";
import Search from "../common/Search";
import SelectSearch from "../common/SelectSearch";
import Notice from "../common/Notice";
import useFetch from "../hooks/useFetch";

const Invoices = ({ customerNames }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [customerFilter, setCustomerFilter] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });
  const { isLoading, data: invoices, error } = useFetch("/api/invoices");

  useEffect(() => {
    if (error) {
      setNotice({
        kind: "error",
        open: true,
        message: "Error retrieving invoice data",
      });
    }
  }, [isLoading]);

  function updateSearchQuery(e) {
    setSearchQuery(e.target.value);
  }

  const customerOptions = [{ value: "", label: "View All" }].concat(
    customerNames.map((name) => ({ value: name, label: name })),
  );

  const statusOptions = [
    { value: "", label: "View All" },
    { value: "Uploaded", label: "Uploaded" },
    { value: "Pending", label: "Pending" },
    { value: "Failed", label: "Failed" },
  ];

  const DEBOUNCE_TIME = 500;

  return (
    <>
      <div className="main-content">
        <div className="main-content__header">
          <h1 className="main-content__heading">Invoices</h1>
        </div>
        <div className="main-content__body">
          <div className="action-bar  action-bar--align-items-flex-end action-bar--mb-16">
            <Search
              className="search--md"
              style={{ flexShrink: 0 }}
              placeholder="Search for a Customer or Invoice Number"
              onChange={debounce(updateSearchQuery, DEBOUNCE_TIME)}
            />
            <div>
              <label className="form__content__label" htmlFor="customer">
                Customer
              </label>
              <SelectSearch
                inputId="customer"
                name="customer"
                testId="customer-filter"
                options={customerOptions}
                defaultValue={customerOptions[0]}
                value={customerFilter || customerOptions[0]}
                size="md"
                onChange={(option) => setCustomerFilter(option)}
              />
            </div>
            <div>
              <label className="form__content__label" htmlFor="status">
                Status
              </label>
              <SelectSearch
                inputId="status"
                name="status"
                testId="status-filter"
                options={statusOptions}
                defaultValue={statusOptions[0]}
                value={statusFilter || statusOptions[0]}
                size="md"
                onChange={(option) => setStatusFilter(option)}
              />
            </div>
            <button
              className="btn icon-text-btn action-bar__item--right"
              onClick={() => Turbo.visit("/invoices/new")}
            >
              <Add className="icon-text-btn__icon" />
              Add New Invoice
            </button>
          </div>
          <InvoicesTable
            invoices={invoices}
            isLoading={isLoading}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            customerFilter={customerFilter?.value}
            statusFilter={statusFilter?.value}
            setNotice={setNotice}
          />
        </div>
      </div>
      <div className="drawer">I'm a React drawer</div>
      <Notice details={notice} />
    </>
  );
};

Invoices.propTypes = {
  customerNames: PropTypes.arrayOf(PropTypes.string),
};

Invoices.defaultProps = {
  customerNames: [],
};

export default Invoices;
