import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Breadcrumbs, Link, Typography, FormControl, TextField, Button } from "@mui/material";

const NewUser = ({ user }) => {
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  return (
    <div className="new-user">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/users">
                Users
              </Link>
              <Typography color="text.primary">New User</Typography>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item xs={5}>
          <h1>New User</h1>
          <form method="POST" action="/users">
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
            />
            <FormControl fullWidth margin="normal">
              <TextField
                id="email"
                name="user[email]"
                label="Email"
                variant="outlined"
                value={email}
                required
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                id="password"
                name="user[password]"
                label="Password"
                variant="outlined"
                value={password}
                type="password"
                required
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                id="password_confirmation"
                name="user[password_confirmation]"
                label="Password Confirmation"
                variant="outlined"
                value={passwordConfirmation}
                type="password"
                required
                onChange={(event) => setPasswordConfirmation(event.target.value)}
              />
            </FormControl>
            <FormControl margin="normal">
              <Button variant="contained" type="submit">
                Save
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

NewUser.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
};

export default NewUser;
