import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatDateToISO } from "../utils/dateTimeUtils";

const EditContract = ({ contract, customers }) => {
  const [name, setName] = useState(contract.name);
  const [customerId, setCustomerId] = useState(contract.customer_id);
  const [effectiveDate, setEffectiveDate] = useState(contract.effective_date);
  const [expirationDate, setExpirationDate] = useState(contract.expiration_date);

  return (
    <div className="edit-contract">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/contracts">
                Contracts
              </Link>
              <Typography color="text.primary">Edit Contract</Typography>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item xs={5}>
          <h1 className="customer-selection__title">Edit Contract</h1>
          <form method="POST" action={`/contracts/${contract.id}`}>
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
            />
            <input name="_method" type="hidden" value="patch" />
            <input type="hidden" name="contract[effective_date]" value={effectiveDate} />
            <input type="hidden" name="contract[expiration_date]" value={expirationDate} />
            <FormControl fullWidth margin="normal">
              <TextField
                name="contract[name]"
                label="Name"
                variant="outlined"
                value={name}
                required
                onChange={(event) => setName(event.target.value)}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="customer">Customer</InputLabel>
              <Select
                labelId="customer"
                name="contract[customer_id]"
                value={customerId}
                label="Customer"
                required
                onChange={(event) => setCustomerId(event.target.value)}
              >
                {customers.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="contract[effective_date]"
                  label="Effective Date"
                  value={dayjs(effectiveDate)}
                  required
                  onChange={(newEffectiveDate) =>
                    setEffectiveDate(formatDateToISO(newEffectiveDate))
                  }
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="contract[expiration_date]"
                  label="Expiration Date"
                  value={dayjs(expirationDate)}
                  required
                  onChange={(newExpirationDate) =>
                    setExpirationDate(formatDateToISO(newExpirationDate))
                  }
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl margin="normal">
              <Button variant="contained" type="submit">
                Save
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

EditContract.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    customer_id: PropTypes.string.isRequired,
    effective_date: PropTypes.string.isRequired,
    expiration_date: PropTypes.string.isRequired,
    termination_date: PropTypes.string,
  }).isRequired,
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default EditContract;
