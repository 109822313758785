import React from "react";
import modalHeaderImg from "../../frontend/images/application/modal_header.svg";
import PropTypes from "prop-types";

const Modal = ({ title, text, buttonLabel, displayModal, handleCancel, handleSubmit }) => {
  if (displayModal)
    return (
      <div className="modal__background" data-testid="modal-bg">
        <div className="modal__container">
          <div className="modal__header">
            <img src={modalHeaderImg} alt="Modal header" className="modal__header__img"></img>
            {handleCancel && (
              <button className="icon-btn" onClick={handleCancel}>
                <span className="material-symbols-rounded">close</span>
              </button>
            )}
          </div>
          <div className="modal__body">
            <div className="modal__body__title">{title}</div>
            <div className="modal__body__text">{text}</div>
          </div>
          <div className="modal__footer">
            {handleCancel && (
              <button className="btn btn--secondary" onClick={handleCancel}>
                Cancel
              </button>
            )}
            <button className="btn" onClick={handleSubmit}>
              {buttonLabel}
            </button>
          </div>
        </div>
      </div>
    );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  displayModal: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  buttonLabel: "OK",
  handleCancel: null,
};

export default Modal;
