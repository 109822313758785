import React, { useMemo } from "react";
import HorizontalBar from "../common/charts/HorizontalBar";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const MOCK_DATA = [
  {
    product: "Stelara",
    matchRate: 0.03,
  },
  {
    product: "Xarelto",
    matchRate: 0.73,
  },
  {
    product: "Tremfya",
    matchRate: 0.24,
  },
  {
    product: "Uptravi",
    matchRate: 0.65,
  },
  {
    product: "Opsumit",
    matchRate: 0.3,
  },
];

const ScrubRatesByPayor = () => {
  const productData = useMemo(
    () =>
      MOCK_DATA.map((obj) => ({
        ...obj,
        matchRate: obj.matchRate * 100,
      })),
    [],
  );

  return (
    <div className="card">
      <div className="card__header">
        <span className="card__title">Scrub Rates by Payor</span>
        <span className="card__timestamp">All Time</span>
      </div>
      <HorizontalBar
        data={productData}
        indexBy="product"
        keys={["matchRate"]}
        color={colors.purpleBlue}
        height="200"
      />
    </div>
  );
};

export default ScrubRatesByPayor;
