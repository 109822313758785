import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import NoResults from "../../common/NoResults";
import { barTheme, barTextStyle } from "./themes";
import textWidth from "../../utils/textWidth";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { white, primaryText, primaryBackground } = colors;

const PADDING = 16;

const { fontSize, fontFamily } = barTextStyle;

const CustomBarLabel = ({ bars }) =>
  bars.map((bar) => {
    const barValue = `${bar.data.value}%`;
    const barWidth = bar.width;
    const barValueWidth = textWidth(barValue, barTextStyle, fontFamily);
    const barValueWidthIsGreaterThanBarWidth = barValueWidth + PADDING > barWidth;
    const barValueXoffset = barValueWidthIsGreaterThanBarWidth
      ? barWidth + PADDING / 2
      : barWidth - PADDING / 2;

    return (
      <g
        key={bar.key}
        transform={`translate(${bar.x + barValueXoffset}, ${bar.y + bar.height / 2})`}
      >
        <text
          x={0}
          y={0}
          textAnchor={barValueWidthIsGreaterThanBarWidth ? "start" : "end"}
          dominantBaseline="middle"
          fill={barValueWidthIsGreaterThanBarWidth ? primaryText : white}
          style={{ fontSize: barTextStyle.fontSize }}
        >
          {barValue}
        </text>
      </g>
    );
  });

const HorizontalBar = ({ data, isLoading, indexBy, keys, color, height, noDataMessage }) => {
  const leftMargin = Math.max(
    ...data.map((dataPoint) => textWidth(dataPoint[indexBy], fontSize, fontFamily)),
  );

  if (!isLoading && !data.length)
    return (
      <NoResults
        description={noDataMessage}
        height={height}
        imgWidth={80}
        backgroundColor={primaryBackground}
      />
    );

  if (isLoading) return <Skeleton variant="rounded" height={height} />;

  return (
    <div className="bar" style={{ height: `${height}px` }}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        borderRadius={2}
        margin={{ top: 0, right: PADDING, bottom: 10, left: leftMargin + PADDING }}
        padding={0.3}
        layout="horizontal"
        valueScale={{ type: "linear", min: 0, max: 100 }}
        indexScale={{ type: "band", round: true }}
        enableGridY={false}
        colors={color}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickValues: [0, 50, 100],
          tickSize: 0,
          tickPadding: -8, // Negative value needed to nest below the bars with the correct padding
          format: (val) => (val === 0 ? val : `${val}%`),
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: PADDING,
        }}
        enableLabel={false}
        layers={["grid", "axes", "bars", "markers", "legends", CustomBarLabel]}
        tooltip={() => null}
        theme={barTheme}
        labelSkipWidth={true}
      />
    </div>
  );
};

HorizontalBar.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  indexBy: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string.isRequired,
  height: PropTypes.number,
  noDataMessage: PropTypes.string,
};

HorizontalBar.defaultProps = {
  data: [],
  height: 400,
  noDataMessage: "No data available at this time.",
};

export default HorizontalBar;
