import React from "react";
import PropTypes from "prop-types";
import KeyTrends from "./KeyTrends";
import CEMatchRate from "./CEMatchRate";
import { formatDateToFirstOfMonthISO, formatDateShort } from "../utils/dateTimeUtils";
import MetricCard from "../common/MetricCard";
import matchRates from "./matchRates";

const renderMetrics = () => (
  <div className="cards-container cards-container--row">
    <MetricCard title="Total Claims" value="1,400,000" />
    <MetricCard title="Total Matches" value="350,000" />
    <MetricCard title="Match Rate" value="25%" />
    <MetricCard title="Covered Entities Submitting" value="2,000" />
  </div>
);

const ClaimsReport = ({ coveredEntities, products, months }) => {
  const mappedCoveredEntities = Object.entries(coveredEntities).map(([value, label]) => ({
    label,
    value,
  }));

  const mappedProducts = products.map((product) => ({ label: product, value: product }));

  const mappedMonths = months.map((month) => {
    const date = month.slice(0, 7);

    return {
      label: formatDateShort(date),
      value: formatDateToFirstOfMonthISO(date),
    };
  });

  return (
    <>
      <div className="main-content__header">
        <h1 className="main-content__heading">Claims Data Reporting</h1>
      </div>
      <div className="main-content__body cards-container">
        {renderMetrics()}
        <KeyTrends coveredEntities={mappedCoveredEntities} products={mappedProducts} />
        <CEMatchRate
          coveredEntities={mappedCoveredEntities}
          products={mappedProducts}
          matchRates={matchRates}
          months={[...mappedMonths, { label: "All Time", value: "" }]}
        />
      </div>
    </>
  );
};

ClaimsReport.propTypes = {
  coveredEntities: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  months: PropTypes.array.isRequired,
};

export default ClaimsReport;
