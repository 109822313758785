import React from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import StatusChip from "../../common/StatusChip";

const ScrubsTable = ({ filename, scrubs, handleDeleteScrub }) =>
  scrubs.length ? (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="scrub rules">
        <TableHead>
          <TableRow>
            <TableCell>Invoice</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scrubs.map(({ id, status }) => (
            <TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>{filename}</TableCell>
              <TableCell>
                <StatusChip status={status} />
              </TableCell>
              <TableCell align="right">
                <Button href={`/scrubs/${id}`}>View</Button>
                <Button onClick={() => handleDeleteScrub(id)} variant="outlined" color="error">
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <p>No scrubs have been created.</p>
  );

ScrubsTable.propTypes = {
  filename: PropTypes.string.isRequired,
  scrubs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleDeleteScrub: PropTypes.func.isRequired,
};

export default ScrubsTable;
