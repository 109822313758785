const textWidth = (text, fontSize, fontFamily, letterSpacing = 0.25) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = `${fontSize}px ${fontFamily}`;
  let width = context.measureText(text).width;
  width += (text.length - 1) * letterSpacing;

  return width;
};

export default textWidth;
