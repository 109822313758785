import React from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Link,
  Paper,
} from "@mui/material";

const CustomersTable = ({ customers, handleDeleteCustomer }) => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="customers table">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {customers.map(({ id, name }) => (
          <TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>
              <Link href={`/customers/${id}`}>{name}</Link>
            </TableCell>
            <TableCell align="right">
              <Button href={`/customers/${id}/edit`}>Edit</Button>
              <Button onClick={() => handleDeleteCustomer(id)} variant="outlined" color="error">
                Delete
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

CustomersTable.propTypes = {
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  handleDeleteCustomer: PropTypes.func.isRequired,
};

export default CustomersTable;
