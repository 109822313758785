import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  TextField,
  Button,
  FormHelperText,
} from "@mui/material";

const NewRebateDetails = ({ rebateTypes, contractId }) => {
  const [formValues, setFormValues] = useState({
    "rebate_detail[ndc11]": "",
    rebate_details: [],
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <div className="new-rebate-details">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/contracts">
                Contracts
              </Link>
              <Link underline="hover" color="inherit" href={`/contracts/${contractId}`}>
                Contract Details
              </Link>
              <Typography color="text.primary">New Rebate Details</Typography>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item xs={5}>
          <h1>New Rebate Details</h1>
          <form method="POST" action={`/contracts/${contractId}/rebate_details`}>
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
            />
            <input type="hidden" name="commit" value="Submit" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal" style={{ marginBottom: "16px" }}>
                  <TextField
                    name="rebate_detail[ndc11]"
                    label="NDC"
                    variant="outlined"
                    onChange={handleFormChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {rebateTypes.map((rebateType, index) => {
              const isPriceProtection = rebateType.base_type === "PriceProtection";
              const isPercentage = rebateType.base_type === "Percentage";

              return (
                <div key={index}>
                  <input
                    type="hidden"
                    name="rebate_details[]rebate_type_id"
                    value={rebateType.id}
                  />
                  <input type="hidden" name="rebate_details[]type" value={rebateType.base_type} />
                  <Grid container spacing={2}>
                    <Grid item xs={isPriceProtection ? 8 : 12}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          name="rebate_details[]value"
                          label={rebateType.name}
                          variant="outlined"
                          onChange={handleFormChange}
                        />
                        {isPercentage && (
                          <FormHelperText>
                            Enter the percentage value in decimal form (e.g. 0.15 for 15%)
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={isPriceProtection ? 4 : 12}>
                      {isPriceProtection && (
                        <FormControl fullWidth margin="normal">
                          <TextField
                            name="rebate_details[]base_wac"
                            label="Base wac"
                            variant="outlined"
                            type="number"
                            onChange={handleFormChange}
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                </div>
              );
            })}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl margin="normal">
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

NewRebateDetails.propTypes = {
  rebateTypes: PropTypes.array.isRequired,
  contractId: PropTypes.string.isRequired,
};

export default NewRebateDetails;
