import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const Bar = ({ data, indexBy, keys, tooltip, height }) => {
  return (
    <div className="bar" style={{ height: `${height}px` }}>
      <ResponsiveBar
        data={data}
        indexBy={indexBy}
        keys={keys}
        margin={{ top: 140, right: 0, bottom: 25, left: 50 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={colors.purpleBlue}
        borderRadius={2}
        axisTop={null}
        axisRight={null}
        axisBottom={{ tickSize: 0, tickPadding: 8 }}
        axisLeft={{
          tickValues: [0, 25, 50, 75, 100],
          tickSize: 0,
          tickPadding: 16,
          format: (val) => `${val}%`,
        }}
        gridYValues={[0, 25, 50, 75, 100]}
        onMouseEnter={(_, event) => (event.target.style.cursor = "pointer")}
        onMouseLeave={(_, event) => (event.target.style.cursor = "default")}
        enableLabel={false}
        tooltip={tooltip}
      />
    </div>
  );
};

Bar.propTypes = {
  data: PropTypes.array,
  indexBy: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  tooltip: PropTypes.func,
  height: PropTypes.string,
};

Bar.defaultProps = {
  data: [],
  tooltip: () => {},
  height: "400",
};

export default Bar;
