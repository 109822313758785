import React from "react";
import noAttachmentImgUrl from "../../frontend/images/application/no_attachment.svg";

const NoAttachment = () => (
  <div className="no-attachment">
    <img src={noAttachmentImgUrl} alt="No attachment" className="no-attachment__img" />
    <div className="no-attachment__header">Nothing attached yet</div>
    <div className="no-attachment__description">Attach a file to get started on your invoice</div>
  </div>
);

export default NoAttachment;
