import React from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Link,
  Paper,
} from "@mui/material";

const UsersTable = ({ users, handleDeleteUser }) => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="users table">
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>Admin</TableCell>
          <TableCell>Created At</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map(({ id, email, created_at, admin }) => (
          <TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>
              <Link href={`/users/${id}`}>{email}</Link>
            </TableCell>
            <TableCell>{admin ? "Yes" : "No"}</TableCell>
            <TableCell>{new Date(created_at).toLocaleString()}</TableCell>
            <TableCell align="right">
              <Button href={`/users/${id}/edit`}>Edit</Button>
              <Button onClick={() => handleDeleteUser(id)} variant="outlined" color="error">
                Delete
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

UsersTable.propTypes = {
  handleDeleteUser: PropTypes.func,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
};

export default UsersTable;
