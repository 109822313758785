import React from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormHelperText,
} from "@mui/material";
import { formatCurrency } from "../../utils/numberUtils";

const RebatesTable = ({ rebateResults }) =>
  rebateResults.length ? (
    <TableContainer>
      <FormHelperText>Displaying the first 25 rebate results.</FormHelperText>
      <Table sx={{ minWidth: 650 }} aria-label="rebates table">
        <TableHead>
          <TableRow>
            <TableCell>Invoice Line ID</TableCell>
            <TableCell>NDC</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rebateResults.map(({ id, invoice_line_id, ndc11, rebate_detail, amount }) => (
            <TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>{invoice_line_id}</TableCell>
              <TableCell>{ndc11}</TableCell>
              <TableCell>{rebate_detail.name}</TableCell>
              <TableCell align="right">{formatCurrency(amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <p>No rebates have been calculated.</p>
  );

RebatesTable.propTypes = {
  rebateResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      invoice_line_id: PropTypes.string.isRequired,
      ndc11: PropTypes.string.isRequired,
      rebate_detail: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      amount: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default RebatesTable;
