import React from "react";
import PropTypes from "prop-types";

const Search = ({ className, name, placeholder, onChange }) => {
  return (
    <div className={`search__container ${className}`}>
      <span className="material-symbols-rounded search__icon">search</span>
      <input
        className="search__input"
        type="search"
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Search.defaultProps = {
  className: "",
  name: "",
  placeholder: "Search",
};

export default Search;
