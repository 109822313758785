import { useState, useEffect, useRef } from "react";

const useConfirmRedirect = (showModalFn, isInitiallyActive = true) => {
  const [isConfirmRedirectActive, setIsConfirmRedirectActive] = useState(isInitiallyActive);
  const resolveRef = useRef(null);

  useEffect(() => {
    Turbo.setConfirmMethod(() => {
      return new Promise((resolve) => {
        showModalFn();
        resolveRef.current = resolve;
      });
    });

    return () => Turbo.setConfirmMethod((message) => window.confirm(message));
  }, []);

  useEffect(() => {
    const links = document.querySelectorAll("a.confirm-modal-trigger");

    if (isConfirmRedirectActive) {
      for (const link of links) {
        const hasMethod = link.getAttribute("data-turbo-method");
        if (!hasMethod) link.dataset.turboMethod = "get";
        link.dataset.turboConfirm = true;
      }
    } else {
      for (const link of links) {
        link.removeAttribute("data-turbo-confirm");
      }
    }

    return () => {
      for (const link of links) {
        link.removeAttribute("data-turbo-confirm");
      }
    };
  }, [isConfirmRedirectActive]);

  function handleCancel() {
    resolveRef.current(false);
  }

  function handleConfirm() {
    resolveRef.current(true);
  }

  return [handleCancel, handleConfirm, setIsConfirmRedirectActive];
};

export default useConfirmRedirect;
