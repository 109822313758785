export const formatCurrency = (value) => {
  if (isNaN(value)) return null;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const addCommasToNumber = (number) => {
  if (number === null || number === undefined || isNaN(number)) return number;
  const num = Number(number);

  return num.toLocaleString();
};

export const formatToPercent = (input, decimalPlaces = 0) => {
  if (input === null || input === undefined || isNaN(input)) return input;
  const num = Number(input);

  return num.toLocaleString("en-US", {
    style: "percent",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

export const decimalToRoundedPercent = (decimal) => Math.round(decimal * 100);
