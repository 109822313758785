import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";
import { formatToPercent } from "../../../utils/numberUtils";
import { noDataChartTheme } from "../themes";
import colors from "../../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { secondaryBackgroundOpacity50 } = colors;

const CHART_DATA = [0.03, 0.73, 0.24, 0.3, 0.65, 0.1].map((value, index) => ({ id: index, value }));

const NoDataHorizontalBar = ({ height }) => {
  return (
    <div className="bar" style={{ height: `${height}px` }}>
      <ResponsiveBar
        data={CHART_DATA}
        keys={["value"]}
        indexBy="id"
        borderRadius={2}
        margin={{ top: -16, right: 16, bottom: 6, left: 4 }}
        padding={0.5}
        layout="horizontal"
        valueScale={{ type: "linear", min: 0, max: 1 }}
        indexScale={{ type: "band", round: true }}
        enableGridY={false}
        colors={secondaryBackgroundOpacity50}
        axisTop={null}
        axisLeft={null}
        axisRight={null}
        axisBottom={{
          tickValues: [0, 0.5, 1],
          tickSize: 0,
          tickPadding: -4,
          format: (val) => (val === 0 ? val : formatToPercent(val)),
        }}
        enableLabel={false}
        tooltip={() => null}
        theme={noDataChartTheme}
      />
      <div className="bar__no-data-text__container">
        <span className="bar__no-data-text__title">No data to display</span>
        <span className="bar__no-data-text__body">
          There is not enough data to populate this graph
        </span>
      </div>
    </div>
  );
};

NoDataHorizontalBar.propTypes = {
  height: PropTypes.string,
};

NoDataHorizontalBar.defaultProps = {
  height: "400",
};

export default NoDataHorizontalBar;
