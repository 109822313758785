export default [
  {
    label: "0 - 10.00%",
    value: 0,
  },
  {
    label: "10.01 - 20.00%",
    value: 0.1,
  },
  {
    label: "20.01 - 30.00%",
    value: 0.2,
  },
  {
    label: "30.01 - 40.00%",
    value: 0.3,
  },
  {
    label: "40.01 - 50.00%",
    value: 0.4,
  },
  {
    label: "50.01 - 60.00%",
    value: 0.5,
  },
  {
    label: "60.01 - 70.00%",
    value: 0.6,
  },
  {
    label: "70.01 - 80.00%",
    value: 0.7,
  },
  {
    label: "80.01 - 90.00%",
    value: 0.8,
  },
  {
    label: "90.01 - 100.00%",
    value: 0.9,
  },
];
