import React, { useState } from "react";
import { Grid, Breadcrumbs, Link, Typography, FormControl, TextField, Button } from "@mui/material";

const NewCustomer = () => {
  const [name, setName] = useState("");

  return (
    <div className="new-customer">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/customers">
                Customers
              </Link>
              <Typography color="text.primary">New Customer</Typography>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item xs={5}>
          <h1>New Customer</h1>
          <form method="POST" action="/customers">
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
            />
            <FormControl fullWidth margin="normal">
              <TextField
                id="name"
                name="customer[name]"
                label="Name"
                variant="outlined"
                value={name}
                required
                onChange={(event) => setName(event.target.value)}
              />
            </FormControl>
            <FormControl margin="normal">
              <Button variant="contained" type="submit">
                Save
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewCustomer;
