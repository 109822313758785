import React, { useState } from "react";
import PropTypes from "prop-types";
import { components } from "react-select";
import Button from "@atlaskit/button/new";
import { PopupSelect } from "@atlaskit/select";
import { addCommasToNumber } from "../utils/numberUtils";

const renderDropdownIndicator = (isOpen) => (
  <span
    className={`material-symbols-rounded multi-select__dropdown-indicator ${isOpen ? "multi-select__dropdown-indicator--is-open" : ""}`}
  >
    keyboard_arrow_down
  </span>
);

const CustomPlaceholder = (props) => {
  return (
    <components.Placeholder {...props}>
      <div className="multi-select__placeholder">Search</div>
    </components.Placeholder>
  );
};

const CustomControl = ({ children, ...props }) => (
  <div className="multi-select__control">
    <components.Control {...props}>
      <div className="multi-select__control__container">
        <span className="material-symbols-rounded">search</span>
        <span>{children[0]}</span>
      </div>
    </components.Control>
  </div>
);

const CustomCheckboxOption = ({
  children,
  isFocused,
  selectedOptions,
  showValueInLabel,
  data: { value },
  ...props
}) => {
  const isSelected = selectedOptions?.some((o) => o.label === children);

  return (
    <div
      className={`multi-select__checkbox-option ${isSelected ? "multi-select__checkbox-option--selected" : ""} ${isFocused ? "multi-select__checkbox-option--focused" : ""}`}
    >
      <components.Option {...props}>
        <div
          className={`multi-select__checkbox-option__checkbox ${isSelected ? "multi-select__checkbox-option__checkbox--selected" : ""}`}
        >
          {isSelected && <span className="material-symbols-sharp">check_small</span>}
        </div>
        <span className="multi-select__checkbox-option__label">
          {showValueInLabel && (
            <span className="multi-select__checkbox-option__value">{value}</span>
          )}
          <span>{children}</span>
        </span>
      </components.Option>
    </div>
  );
};

const NoOptionsMessage = ({ ...props }) => {
  return (
    <components.NoOptionsMessage {...props}>
      <div className="multi-select__no-options-message">No results found</div>
    </components.NoOptionsMessage>
  );
};

const MultiSelect = ({
  label,
  options,
  selectedOptions,
  onChange,
  onClear,
  showValueInLabel,
  hideSearch,
  searchThreshold,
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (value, event) => {
    if (event.action === "input-change") {
      setInputValue((prevInputValue) => {
        if (prevInputValue && value === "") {
          return "";
        }
        return value || prevInputValue;
      });
      const filtered = options.filter(
        (option) =>
          option.label.toLowerCase().includes(value.toLowerCase()) ||
          option.value.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredOptions(filtered);
    }
  };

  const handleChange = (selected) => onChange(selected);

  const numberOfSelectedOptions = selectedOptions.length;
  const hasSelectedOptions = numberOfSelectedOptions > 0;
  const numberOfFilteredOptions = filteredOptions.length;

  return (
    <div className="multi-select">
      <PopupSelect
        components={{
          Option: (props) => (
            <CustomCheckboxOption
              {...props}
              selectedOptions={selectedOptions}
              showValueInLabel={showValueInLabel}
            />
          ),
          Control: CustomControl,
          Placeholder: CustomPlaceholder,
          NoOptionsMessage,
        }}
        options={options}
        minMenuWidth={300}
        maxMenuWidth={300}
        maxMenuHeight={300}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isMulti
        searchThreshold={hideSearch ? options.length + 1 : searchThreshold}
        target={({ isOpen, ...triggerProps }) => {
          return (
            <div
              className={`multi-select__target ${isOpen ? "multi-select__target--open" : ""} ${hasSelectedOptions ? "multi-select__target--has-selected" : ""}`}
            >
              <Button {...triggerProps} iconAfter={() => renderDropdownIndicator(isOpen)}>
                {label}
                {hasSelectedOptions && (
                  <div className="multi-select__badge" data-testid="multi-select-badge">
                    {numberOfSelectedOptions}
                  </div>
                )}
              </Button>
            </div>
          );
        }}
        footer={
          <div className="multi-select__footer" data-testid="multi-select-footer">
            {selectedOptions.length > 0 && (
              <button className="btn btn--plain" onClick={onClear}>
                Clear Selection
              </button>
            )}
            <div className="multi-select__footer__text">{`${addCommasToNumber(numberOfFilteredOptions)} ${numberOfFilteredOptions !== 1 ? "results" : "result"}`}</div>
          </div>
        }
        onInputChange={(value, event) => handleInputChange(value, event)}
        onChange={handleChange}
        value={selectedOptions}
        inputValue={inputValue}
      />
    </div>
  );
};

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  showValueInLabel: PropTypes.bool,
  searchThreshold: PropTypes.number,
  hideSearch: PropTypes.bool,
};

MultiSelect.defaultProps = {
  selectedOptions: [],
  showValueInLabel: false,
  searchThreshold: 5,
  hideSearch: false,
};

CustomCheckboxOption.propTypes = {
  children: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  showValueInLabel: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default MultiSelect;
