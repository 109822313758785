import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const EditUser = ({ currentUser, user }) => {
  const [email] = useState(user.email);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isAdmin, setIsAdmin] = useState(user.admin);

  return (
    <div className="edit-user">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/users">
                Users
              </Link>
              <Typography color="text.primary">Edit User</Typography>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item xs={5}>
          <h1>Edit User</h1>
          <form method="POST" action={`/users/${user.id}`}>
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
            />
            <input name="_method" type="hidden" value="patch" />
            <FormControl fullWidth margin="normal">
              <TextField
                id="name"
                name="user[email]"
                label="Email"
                variant="outlined"
                value={email}
                required
                disabled
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                id="name"
                name="user[password]"
                label="Password"
                variant="outlined"
                value={password}
                type="Password"
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                id="name"
                name="user[password_confirmation]"
                label="Password Confirmation"
                variant="outlined"
                value={passwordConfirmation}
                type="password"
                onChange={(event) => setPasswordConfirmation(event.target.value)}
              />
            </FormControl>
            {currentUser.admin && (
              <FormControl fullWidth margin="normal">
                <input type="hidden" name="user[admin]" value={isAdmin} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAdmin}
                      onChange={(event) => setIsAdmin(event.target.checked)}
                      color="primary"
                    />
                  }
                  label="Admin"
                />
              </FormControl>
            )}
            <FormControl margin="normal">
              <Button variant="contained" type="submit">
                Save
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

EditUser.propTypes = {
  currentUser: PropTypes.shape({
    admin: PropTypes.bool.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    admin: PropTypes.bool.isRequired,
  }).isRequired,
};

export default EditUser;
