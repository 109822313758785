import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import debounce from "debounce-promise";
import useFetch from "../hooks/useFetch";
import Notice from "../common/Notice";
import BarWithLine from "../common/charts/BarWithLine";
import {
  formatDatePartialMonthPartialYear,
  formatDateFullMonthFullYear,
} from "../utils/dateTimeUtils";
import { addCommasToNumber, formatToPercent } from "../utils/numberUtils";
import { createQueryString } from "../utils/urlUtils";
import { extractValues } from "../utils/arrayUtils";
import { barTextStyle } from "../common/charts/themes";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const { primaryText } = colors;

const DEBOUNCE_TIME = 500;

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const ClaimCountAndMatchRate = ({ filters }) => {
  const [notice, setNotice] = useState(initialNoticeState);
  const { get: getClaimCountAndMatchRateData, isLoading, data, error } = useFetch();

  const debouncedFetchClaimCountAndMatchRateData = useCallback(
    debounce(getClaimCountAndMatchRateData, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (error) {
      setNotice({
        kind: "error",
        open: true,
        message: "Error retrieving claim count and match rate data",
      });
    }
  }, [error]);

  useEffect(() => {
    const { coveredEntities, products } = filters;
    const queryString = createQueryString({
      group_by: "month",
      id_340b: extractValues(coveredEntities),
      product_name: extractValues(products),
    });

    debouncedFetchClaimCountAndMatchRateData(`/api/reports/match_rate_report${queryString}`);
  }, [filters]);

  const renderTooltip = ({ data: { id, totalMatches, totalClaims, matchRate } }) => {
    const formattedMatchValue = addCommasToNumber(totalMatches);
    const formattedSubmissionsValue = addCommasToNumber(totalClaims);

    return (
      <div className="tooltip__content">
        <div className="tooltip__content__title">{formatDateFullMonthFullYear(id)}</div>
        <div className="tooltip__content__details">
          <span>{`${formattedMatchValue} Match`}</span>
          <span>{`${formattedSubmissionsValue} Total Submissions`}</span>
          <span>{`${formatToPercent(matchRate)} Match Rate`}</span>
        </div>
      </div>
    );
  };

  const renderCustomBottomTick = (obj) => {
    const formattedDate = formatDatePartialMonthPartialYear(obj.value);
    const [month, year] = formattedDate.split(" ");

    return (
      <g
        transform={`translate(${obj.x},${obj.y + 12})`}
        style={{ fontSize: barTextStyle.fontSize }}
        fill={primaryText}
      >
        <text textAnchor="middle">{month}</text>
        <text transform={`translate(0, 12)`} textAnchor="middle">
          {year}
        </text>
      </g>
    );
  };

  const keys = {
    bars: [
      { key: "totalMatches", label: "Match" },
      { key: "totalNoMatches", label: "No Match" },
    ],
    line: { key: "matchRate", label: "Match Rate" },
  };

  return (
    <>
      <div className="card card--width-60pct" data-testid="claim-count-and-match-rate">
        <div className="card__title">Claim Count and Match Rate</div>
        <BarWithLine
          data={data?.map((obj) => ({ ...obj, id: obj.groupByValue }))}
          isLoading={isLoading}
          keys={keys}
          renderTooltip={renderTooltip}
          renderCustomBottomTick={renderCustomBottomTick}
          height={250}
          noDataMessage="There is no data that matches your filter selection"
        />
        {/* <div className="table--scroll">
        <table className="table table--secondary">
          <thead>
            <tr>
              <th>Date</th>
              <th className="align-right">Match</th>
              <th className="align-right">Total Submissions</th>
              <th className="align-right">Match Rate</th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ id, match, submissions, matchRate }) => {
              const month = id.split(" ")[0];
              const formattedMatchValue = addCommasToNumber(match);
              const formattedSubmissionsValue = addCommasToNumber(submissions);

              return (
                <tr key={id}>
                  <td>{month}</td>
                  <td className="align-right">{formattedMatchValue}</td>
                  <td className="align-right">{formattedSubmissionsValue}</td>
                  <td className="align-right">{matchRate}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> */}
      </div>
      <Notice details={notice} />
    </>
  );
};

ClaimCountAndMatchRate.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default ClaimCountAndMatchRate;
