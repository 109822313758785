import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { PRICE_PROTECTION, PERCENTAGE, FIXED_AMOUNT } from "./rebateTypes";

const baseTypeOptions = [PRICE_PROTECTION, PERCENTAGE, FIXED_AMOUNT];

const NewRebateType = ({ rebateTypes, returnTo }) => {
  const [baseType, setBaseType] = useState("");
  const [name, setName] = useState("");

  return (
    <div className="new-rebate-type">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/contracts">
                Contracts
              </Link>
              <Link underline="hover" color="inherit" href={`${returnTo}`}>
                Contract Details
              </Link>
              <Typography color="text.primary">New Rebate Type</Typography>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item xs={5}>
          <h1>New Rebate Type</h1>
          <form method="POST" action={`/customers/${rebateTypes.customer_id}/rebate_types`}>
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="demo-simple-select-label">Base Type</InputLabel>
              <Select
                name="rebate_type[base_type]"
                label="Base Type"
                value={baseType}
                required
                onChange={(event) => setBaseType(event.target.value)}
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {baseTypeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                name="rebate_type[name]"
                label="Name"
                variant="outlined"
                value={name}
                required
                onChange={(event) => setName(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

NewRebateType.propTypes = {
  rebateTypes: PropTypes.shape({
    customer_id: PropTypes.string,
  }).isRequired,
  returnTo: PropTypes.string.isRequired,
};

export default NewRebateType;
