import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

const LoadingSkeletonWrapper = ({ isLoading, children, width }) => {
  if (isLoading) return <Skeleton animation="wave" width={width} />;

  return children;
};

LoadingSkeletonWrapper.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LoadingSkeletonWrapper.defaultProps = {
  isLoading: false,
  children: <></>,
  width: "80%",
};

export default LoadingSkeletonWrapper;
