import React, { useState } from "react";
import PropTypes from "prop-types";

const ToggleSwitch = ({ options, className, onSelection }) => {
  const [selection, setSelection] = useState(options[0]);

  const handleSelection = (option) => {
    setSelection(option);
    onSelection();
  };

  return (
    <div className={`toggle-switch ${className}`}>
      {options.map((option) => {
        const isSelected = option === selection;

        return (
          <button
            className={`${isSelected ? "selected" : ""}`}
            key={option}
            onClick={() => !isSelected && handleSelection(option)}
          >
            {option}
          </button>
        );
      })}
    </div>
  );
};

ToggleSwitch.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  onSelection: PropTypes.func.isRequired,
};

ToggleSwitch.defaultProps = {
  className: "",
};

export default ToggleSwitch;
