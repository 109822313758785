import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import XLSX from "xlsx";
import NoAttachment from "../../common/NoAttachment";
import Modal from "../../common/Modal";
import NewInvoiceDrawer from "./NewInvoiceDrawer";
import SelectWorksheet from "./SelectWorksheet";
import MapData from "./MapData";
import stepDetails from "./stepDetails";
import Breadcrumbs from "../../common/Breadcrumbs";
import useFetch from "../../hooks/useFetch";
import { formatDateToISO } from "../../utils/dateTimeUtils";
import reoInvoiceColumns from "./reoInvoiceColumns";
import useConfirmRedirect from "../../hooks/useConfirmRedirect";

const getFileDataFromWorksheet = (ws) =>
  XLSX.utils.sheet_to_json(ws, {
    header: 1, // Option to return an array of arrays
    raw: false,
    blankrows: false,
    defval: "",
  });

const fileHasHeaderMatch = (fileData, headerRow, latestHeaders) => {
  return fileData[headerRow]?.some((header) => latestHeaders.includes(header));
};

const NewInvoice = ({ customers }) => {
  const [currentStep, setCurrentStep] = useState(stepDetails.NO_ATTACHMENT);
  const [invoiceAttachment, setInvoiceAttachment] = useState({
    type: "",
    stored_file: undefined,
  });
  const [workbook, setWorkbook] = useState("");
  const [worksheets, setWorksheets] = useState([]);
  const [selectedSheetName, setSelectedSheetName] = useState();
  const [rawInvoiceData, setRawInvoiceData] = useState([]);
  const [headerRowIndex, setHeaderRowIndex] = useState();
  const [mappingSelections, setMappingSelections] = useState({});
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const [basicInfo, setBasicInfo] = useState({
    customerId: "",
    number: "",
    date: "",
    dueDate: "",
    originalAmount: "",
    stored_file: undefined,
  });

  const showModal = (modal) => {
    setSelectedModal(modal);
    setDisplayModal(true);
  };

  const [handleCancelLeavePage, handleConfirmLeavePage, setIsConfirmRedirectActive] =
    useConfirmRedirect(() => showModal("leavePage"), false);

  const modalProps = {
    goBack: {
      title: "Go Back?",
      text: "If you wish to make a change, you will lose your progress on this page.",
      buttonLabel: "Confirm",
      handleCancel: () => setDisplayModal(false),
      handleSubmit: () => {
        setDisplayModal(false);
        handleFileChange(undefined, "");
      },
    },
    discard: {
      title: "Discard New Invoice?",
      text: "Please confirm if you want to discard your new invoice. If you proceed, you will lose all of the changes you have made. This cannot be undone.",
      buttonLabel: "Discard New Invoice",
      handleCancel: () => setDisplayModal(false),
      handleSubmit: () => Turbo.visit("/invoices"),
    },
    selectWorksheet: {
      title: "Go Back?",
      text: "If you wish to make a change, you will lose your progress on this page.",
      buttonLabel: "Confirm",
      handleCancel: () => setDisplayModal(false),
      handleSubmit: () => {
        setCurrentStep(stepDetails.SELECT_WORKSHEET);
        setSelectedSheetName(undefined);
        setHeaderRowIndex(undefined);
        setMappingSelections({});
        setDisplayModal(false);
      },
    },
    leavePage: {
      title: "Leave Page?",
      text: "Please confirm if you want to leave this page. If you proceed, you will lose all of the changes you have made. This cannot be undone.",
      buttonLabel: "Leave Page",
      handleCancel: () => {
        handleCancelLeavePage();
        setDisplayModal(false);
      },
      handleSubmit: handleConfirmLeavePage,
    },
    successfulUpload: {
      title: "Successful Upload",
      text: "You have successfully uploaded your invoice. It is now processing and will appear in the Invoices table once it is complete.",
      buttonLabel: "Return to Invoices Home",
      handleSubmit: () => Turbo.visit("/invoices"),
    },
  };

  useEffect(() => {
    const isAnyFieldFilled = Object.values(basicInfo).some((val) => Boolean(val));
    setIsConfirmRedirectActive(isAnyFieldFilled);
  }, [basicInfo]);

  const { post } = useFetch();
  const { get, data: latestInvoiceData } = useFetch();

  const getLatestInvoiceData = (customerId) => {
    get(`/api/customers/${customerId}/latest_invoice`);
  };

  const setMappingSelectionsFromLatestInvoiceData = (rawFileHeaders) => {
    setMappingSelections(
      Object.fromEntries(
        reoInvoiceColumns
          .map(({ value }) => {
            const mapping = latestInvoiceData?.invoice_mapping[value];
            return [value, rawFileHeaders.includes(mapping) ? mapping : undefined];
          })
          .filter((mapping) => mapping[1]),
      ),
    );
  };

  const setMappingColumnDataStep = (headerRow, rawFileHeaders) => {
    setMappingSelectionsFromLatestInvoiceData(rawFileHeaders);
    setHeaderRowIndex(headerRow);
    setCurrentStep(stepDetails.MAP_COLUMN_DATA);
  };

  const handleXlsFile = (worksheetName, headerRow, headers) => {
    let wsName;

    if (workbook.SheetNames.length === 1) {
      wsName = workbook.SheetNames[0];
    } else {
      wsName = worksheetName;
    }

    const ws = workbook.Sheets[wsName];

    if (!ws) {
      setCurrentStep(stepDetails.SELECT_WORKSHEET);
      return;
    }

    const fileData = getFileDataFromWorksheet(ws);
    setSelectedSheetName(wsName);

    if (fileHasHeaderMatch(fileData, headerRow, headers)) {
      setMappingColumnDataStep(headerRow, fileData[headerRow]);
    } else {
      setCurrentStep(stepDetails.SELECT_WORKSHEET);
    }

    processAndSetRawInvoiceData(ws);
  };

  const handleTextBasedFile = (headerRow, headers) => {
    let worksheet;
    const fileData = getFileDataFromWorksheet(workbook.Sheets.Sheet1);

    if (fileHasHeaderMatch(fileData, headerRow, headers)) {
      worksheet = workbook.Sheets.Sheet1;
      setMappingColumnDataStep(headerRow, fileData[headerRow]);
    } else {
      worksheet = workbook.Sheets[workbook.SheetNames[0]];
      setCurrentStep(stepDetails.SELECT_WORKSHEET);
    }

    processAndSetRawInvoiceData(worksheet);
  };

  const isTextBasedFileType = (fileType) => ["csv", "txt", "tsv"].includes(fileType);

  useEffect(() => {
    if (invoiceAttachment.stored_file) {
      handleInvoiceFile();
    } else {
      setCurrentStep(stepDetails.NO_ATTACHMENT);
    }
  }, [invoiceAttachment.stored_file]);

  useEffect(() => {
    if (workbook) {
      let worksheet_name, header_row, headers;

      if (latestInvoiceData) {
        worksheet_name = latestInvoiceData.worksheet_name;
        header_row = latestInvoiceData.header_row;
        headers = latestInvoiceData.headers;
      }

      if (isTextBasedFileType(invoiceAttachment.type)) {
        handleTextBasedFile(header_row, headers);
      } else {
        handleXlsFile(worksheet_name, header_row, headers);
      }
    }
  }, [workbook]);

  const handleFileChange = (stored_file, type) => {
    if (!stored_file) {
      setCurrentStep(stepDetails.NO_ATTACHMENT);
      setSelectedSheetName(undefined);
      setRawInvoiceData([]);
      setHeaderRowIndex(undefined);
      setMappingSelections({});
    }

    setInvoiceAttachment({
      type,
      stored_file,
    });

    setBasicInfo({
      ...basicInfo,
      stored_file,
    });
  };

  const handleInvoiceSubmit = ({
    customerId,
    number,
    date,
    dueDate,
    originalAmount,
    stored_file,
  }) => {
    const formData = new FormData();
    formData.append("invoice[customer_id]", customerId);
    formData.append("invoice[date]", formatDateToISO(date));
    formData.append("invoice[due_date]", formatDateToISO(dueDate));
    formData.append("invoice[number]", number);
    formData.append("invoice[total_amount]", originalAmount);
    formData.append("file[header_row]", headerRowIndex);
    formData.append("file[worksheet_name]", selectedSheetName);
    rawInvoiceData[headerRowIndex].forEach((header) => {
      formData.append("file[headers][]", header);
    });
    formData.append("file[attachment_attributes][stored_file]", stored_file);
    Object.entries(mappingSelections).forEach(([key, value]) => {
      formData.append(`file[invoice_mapping_attributes][${key}]`, value);
    });

    post("/api/invoices", formData)
      .then(() => showModal("successfulUpload"))
      .catch((error) => alert(error));
  };

  const handleInvoiceFile = () => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (event) => {
      const bstr = event.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      setWorkbook(wb);
      setWorksheets(wb.SheetNames.map((name) => ({ value: name, label: name })));
    };

    if (rABS) {
      reader.readAsBinaryString(invoiceAttachment.stored_file);
    } else {
      reader.readAsArrayBuffer(invoiceAttachment.stored_file);
    }
  };

  const processAndSetRawInvoiceData = (ws) => {
    const fileData = getFileDataFromWorksheet(ws);

    setRawInvoiceData(fileData);
  };

  const handleSelectWorksheet = ({ value }) => {
    const ws = workbook.Sheets[value];
    setSelectedSheetName(value);
    processAndSetRawInvoiceData(ws);
  };

  const handleConfirmWorksheet = () => {
    setCurrentStep(stepDetails.MAP_COLUMN_DATA);
  };

  const renderSelectWorksheetStep = () => (
    <SelectWorksheet
      data={rawInvoiceData}
      worksheets={worksheets}
      selectedSheetName={selectedSheetName}
      isTextBasedFileType={isTextBasedFileType(invoiceAttachment.type)}
      onSelectWorksheet={handleSelectWorksheet}
      headerRowIndex={headerRowIndex}
      setHeaderRowIndex={setHeaderRowIndex}
      onConfirm={handleConfirmWorksheet}
    />
  );

  const renderDataMappingStep = () => (
    <MapData
      fileHeaders={rawInvoiceData[headerRowIndex] || []}
      data={rawInvoiceData}
      headerRowIndex={headerRowIndex}
      mappingSelections={mappingSelections}
      setMappingSelections={setMappingSelections}
      reoHeaders={reoInvoiceColumns}
    />
  );

  const renderCurrentStepComponent = () => {
    switch (currentStep) {
      case stepDetails.NO_ATTACHMENT:
        return <NoAttachment />;
      case stepDetails.SELECT_WORKSHEET:
        return renderSelectWorksheetStep();
      default:
        return renderDataMappingStep();
    }
  };

  const renderBreadcrumbs = () => {
    const currentStepIndex = Object.values(stepDetails).indexOf(currentStep);
    const breadcrumbs = Object.values(stepDetails)
      .slice(0, currentStepIndex + 1)
      .map(({ breadcrumb }) => breadcrumb);

    const handleBreadcrumbClick = (breadcrumb) => {
      if (
        breadcrumb === stepDetails.NO_ATTACHMENT.breadcrumb &&
        currentStep !== stepDetails.NO_ATTACHMENT
      ) {
        return showModal("goBack");
      }

      if (
        breadcrumb === stepDetails.SELECT_WORKSHEET.breadcrumb &&
        currentStep === stepDetails.MAP_COLUMN_DATA
      ) {
        return showModal("selectWorksheet");
      }

      setCurrentStep(
        stepDetails[
          Object.values(stepDetails).find((stepDetail) => stepDetail.breadcrumb === breadcrumb).step
        ],
      );
    };

    return <Breadcrumbs breadcrumbs={breadcrumbs} handleBreadcrumbClick={handleBreadcrumbClick} />;
  };

  const { subheading } = currentStep;

  return (
    <>
      <div className="main-content">
        <div
          className={`main-content__header ${subheading ? "main-content__header--fixed-height" : ""}`}
        >
          {renderBreadcrumbs()}
          <div className="main-content__heading">Add New Invoice</div>
        </div>
        <div
          className={`main-content__body ${currentStep === stepDetails.MAP_COLUMN_DATA ? "main-content__body--flex-row main-content__body--white-bg" : ""}`}
        >
          {renderCurrentStepComponent()}
        </div>
      </div>
      <div className="drawer">
        <NewInvoiceDrawer
          customers={customers}
          getLatestInvoiceData={getLatestInvoiceData}
          hasAssignedMapping={!!Object.keys(mappingSelections).length}
          onSubmit={handleInvoiceSubmit}
          showModal={showModal}
          setBasicInfo={setBasicInfo}
          basicInfo={basicInfo}
          handleFileChange={handleFileChange}
        />
      </div>
      {selectedModal && <Modal {...modalProps[selectedModal]} displayModal={displayModal} />}
    </>
  );
};

NewInvoice.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NewInvoice;
