import axios from "axios";

export const handleDeleteResource = (id, resourceType) => {
  const formData = new FormData();
  formData.append("_method", "DELETE");
  formData.append(
    "authenticity_token",
    document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
  );

  return axios.post(`/${resourceType}/${id}`, formData);
};
