import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import useInitialColumnWidths from "./useInitialColumnWidths";

const NewInvoiceTable = ({ data, setHeaderRowIndex, onConfirm }) => {
  const tableRef = useRef(null);

  const columnHelper = createColumnHelper();

  const keys = Object.keys(data[0] || {});

  const columns = useMemo(() => {
    return keys.map((key, index) => {
      return columnHelper.accessor(key, {
        header: `${key}-${index}`,
        cell: (info) => info.row.original[key],
        id: `column-${index}`,
      });
    });
  }, [data]);

  const { getCellWidth } = useInitialColumnWidths(columns, data, false);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const parentRef = useRef(null);

  const virtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 48,
    overscan: 20,
  });

  const items = virtualizer.getVirtualItems();

  const renderVirtualRows = () => {
    return items.map((virtualRow, index) => {
      const row = rows[virtualRow.index];

      return (
        <tr
          className="table__row--select-header"
          key={row.id}
          style={{
            transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
          }}
          onClick={() => handleSelectHeaderRow(row)}
        >
          <td />
          {row.getVisibleCells().map((cell, i) => (
            <td key={cell.id} style={{ width: getCellWidth(i) }}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      );
    });
  };

  const handleSelectHeaderRow = (row) => {
    setHeaderRowIndex(row.index);
    onConfirm();
  };

  return (
    <div ref={parentRef} className="table__container--border table--scroll">
      <div style={{ minHeight: `${virtualizer.getTotalSize()}px` }}>
        <table
          ref={tableRef}
          className="table table--secondary table--virtualized"
          data-testid="new-invoice-table"
        >
          <tbody>{renderVirtualRows()}</tbody>
        </table>
      </div>
    </div>
  );
};

NewInvoiceTable.propTypes = {
  data: PropTypes.array.isRequired,
  setHeaderRowIndex: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default NewInvoiceTable;
