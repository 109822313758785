import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, Link } from "@mui/material";

const ContractsList = ({ contracts }) => (
  <List>
    {contracts.map(({ id, name }) => (
      <ListItem key={id} disableGutters>
        <Link href={`/contracts/${id}`}>{name}</Link>
      </ListItem>
    ))}
  </List>
);

ContractsList.propTypes = {
  contracts: PropTypes.array.isRequired,
};

export default ContractsList;
