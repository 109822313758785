import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button, Snackbar, Alert } from "@mui/material";
import UsersTable from "./UsersTable";
import { handleDeleteResource } from "../services/resourceService";

const Users = ({ users: initialUsers }) => {
  const [users, setUsers] = useState(initialUsers);
  const [errorState, setErrorState] = useState({
    hasError: false,
    message: "",
  });

  const handleDeleteUser = (userId) => {
    handleDeleteResource(userId, "users")
      .then(() => {
        setUsers(users.filter(({ id }) => id !== userId));
      })
      .catch(() => {
        setErrorState({
          hasError: true,
          message:
            "You cannot delete a user that has invoices, customers, or contracts. Please remove them and try again.",
        });
      });
  };

  const handleAlertClose = () => {
    setErrorState({
      hasError: false,
      message: "",
    });
  };

  return (
    <div className="users">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <h1>Users</h1>
            </Grid>
            <Grid item>
              <Button variant="contained" href="/users/new">
                New User
              </Button>
            </Grid>
          </Grid>
          <UsersTable users={users} handleDeleteUser={handleDeleteUser} />
        </Grid>
      </Grid>
      <Snackbar open={errorState.hasError} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

Users.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Users;
