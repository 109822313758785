import React, { useMemo } from "react";
import Bar from "../common/charts/Bar";
import { addCommasToNumber } from "../utils/numberUtils";

const MOCK_DATA = [
  { product: "Stelara", match: 34_749, submissions: 84_759 },
  { product: "Xarelto", match: 362_367, submissions: 559_734 },
  { product: "Tremfya", match: 6_549, submissions: 17_680 },
  { product: "Uptravi", match: 894, submissions: 9_001 },
  { product: "Opsumit", match: 990, submissions: 14_845 },
];

const MatchRatesByProductVertical = () => {
  const productData = useMemo(
    () =>
      MOCK_DATA.map((obj) => ({
        ...obj,
        matchRate: ((obj.match / obj.submissions) * 100).toFixed(),
      })),
    [],
  );

  const { totalMatches, totalSubmissions, totalMatchRate } = useMemo(
    () =>
      MOCK_DATA.reduce(
        (acc, curr) => {
          const totalMatches = acc.totalMatches + curr.match;
          const totalSubmissions = acc.totalSubmissions + curr.submissions;
          const totalMatchRate = ((totalMatches / totalSubmissions) * 100).toFixed();

          return { totalMatches, totalSubmissions, totalMatchRate };
        },
        { totalMatches: 0, totalSubmissions: 0, totalMatchRate: 0 },
      ),
    [],
  );

  const renderTooltip = ({ data }) => {
    const { product, match, submissions, matchRate } = data;
    const formattedMatchValue = addCommasToNumber(match);
    const formattedSubmissionsValue = addCommasToNumber(submissions);
    const formattedMatchRateValue = `${matchRate}%`;

    return (
      <div className="tooltip__content">
        <div className="tooltip__content__title">{product}</div>
        <div className="tooltip__content__details">
          <span>{`${formattedMatchValue} Match`}</span>
          <span>{`${formattedSubmissionsValue} Total Submissions`}</span>
          <span>{`${formattedMatchRateValue} Match Rate`}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="card" data-testid="match-rates-by-product">
      <div className="card__title">Match Rates by Product</div>
      <Bar data={productData} indexBy="product" keys={["matchRate"]} tooltip={renderTooltip} />
      <div className="table--scroll">
        <table className="table table--secondary">
          <thead>
            <tr>
              <th>Product</th>
              <th className="align-right">Match</th>
              <th className="align-right">Total Submissions</th>
              <th className="align-right">Match Rate</th>
            </tr>
          </thead>
          <tbody>
            {productData.map(({ product, match, submissions, matchRate }) => (
              <tr key={product}>
                <td>{product}</td>
                <td className="align-right">{addCommasToNumber(match)}</td>
                <td className="align-right">{addCommasToNumber(submissions)}</td>
                <td className="align-right">{matchRate}%</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <td className="align-right">{addCommasToNumber(totalMatches)}</td>
              <td className="align-right">{addCommasToNumber(totalSubmissions)}</td>
              <td className="align-right">{totalMatchRate}%</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default MatchRatesByProductVertical;
