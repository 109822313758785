import { useState, useEffect } from "react";
import PropTypes from "prop-types";

function useFetch(endpoint = "") {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (endpoint) {
      fetchApi(endpoint, baseOptions);
    }
  }, []);

  const baseOptions = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.getAttribute("content"),
    },
  };

  async function fetchApi(endpoint, options) {
    try {
      const res = await fetch(endpoint, options);
      const json = await res.json();
      if (!res.ok) throw new Error(json?.error || "Error fetching data");

      setData(json);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  const get = (endpoint) => {
    setIsLoading(true);
    return fetchApi(endpoint, {
      ...baseOptions,
      method: "GET",
    });
  };

  const post = (endpoint, data) => {
    setIsLoading(true);
    const options = {
      ...baseOptions,
      method: "POST",
    };

    if (data instanceof FormData) {
      // If data is FormData (for form data with files), set Content-Type header to undefined
      delete options.headers["Content-Type"];
      options.body = data;
    } else {
      // If data is JSON, stringify it and set Content-Type header to application/json
      options.headers["Content-Type"] = "application/json";
      options.body = JSON.stringify(data);
    }

    return fetchApi(endpoint, options);
  };

  return { data, isLoading, error, get, post };
}

useFetch.PropTypes = {
  endpoint: PropTypes.string,
};

export default useFetch;
